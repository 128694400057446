import React from "react";

// Styles \\
import styles from "./Home.module.scss";

// Components \\
import Header from "../../components/Header/Header";
import RegistrationSection from "../../components/RegistrationSection/RegistrationSection";
import AboutCourseSection from "../../components/AboutCourseSection/AboutCourseSection";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  // JSX \\
  return (
    <React.Fragment>
      <Header />
      <main className={styles.home}>
        <RegistrationSection />
        <AboutCourseSection />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
