import React from "react";

// Styles \\
import styles from "./Header.module.scss";

const Header = () => {
  // JSX \\
  return (
    <header className={styles.header}>
      <nav className={`${styles.header__nav} container`}>
        <div className={styles.header__nav_logo1}>
          <img src="./logo-1.png" alt="Logo 1" />
        </div>

        <div className={styles.header__nav_logo2}>
          <img src="./logo-2.png" alt="Logo 2" />
        </div>
      </nav>

      <div className={`${styles.header__heading} container`}>
        <h1>Get Certified By</h1>
        <h2>
          Kavayitri Bahinabai Chaudhari North Maharashtra University, Jalgaon
        </h2>
      </div>

      <div className={`${styles.header__hero_img} container`}>
        <img src="/hero.png" alt="Hero Img" />

        <div className={styles.header__hero_img_title}>
          <h3>A comprehensive certification course on</h3>
          <p>Illustration & 2D Graphic Design</p>
        </div>
      </div>
    </header>
  );
};

export default Header;
