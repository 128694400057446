import { useState } from "react";

// Firebase Firestore & Timestamp \\
import { projectFirestore, timestamp } from "../firebase/config";

export const useAddDocument = (collection) => {
  // Reducer \\
  const [isPending, setIsPending] = useState(false);

  // Collection Reference \\
  const reference = projectFirestore.collection(collection);

  // Add a Document to Firebase Collection \\
  const addDocument = async (doc) => {
    setIsPending(true);

    try {
      const createdAt = timestamp.fromDate(new Date());

      await reference.add({ ...doc, createdAt });

      setIsPending(false);
    } catch (error) {
      setIsPending(false);
      console.log(error);
    }
  };

  return { isPending, addDocument };
};
