import React, { useState } from "react";

// Styles \\
import styles from "./RegistrationSection.module.scss";

// Custom Hooks \\
import { useAddDocument } from "../../hooks/useAddDocument";

const RegistrationSection = () => {
  // States \\
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [education, setEducation] = useState("");
  const [collegeUniversity, setCollegeUniversity] = useState("");
  const [steamOfStudy, setSteamOfStudy] = useState("");
  const [educationalQualification, setEducationalQualification] = useState("");
  const [residing, setResiding] = useState("");
  const [programInfo, setProgramInfo] = useState("");
  const [laptopPc, setLaptopPc] = useState("");

  // useFirestore Hook \\
  const { isPending, addDocument } = useAddDocument("registered-users");

  // On Form Submit \\
  const submitHandler = (event) => {
    event.preventDefault();

    // Save User Information In Firebase Firestore \\
    addDocument({
      name,
      number,
      email,
      education,
      collegeUniversity,
      steamOfStudy,
      educationalQualification,
      residing,
      programInfo,
      laptopPc,
    });

    // Create Form Inputs If Form Successfully Submitted \\
    if (!isPending) {
      setName("");
      setNumber("");
      setEmail("");
      setEducation("");
      setCollegeUniversity("");
    }
  };

  // JSX \\
  return (
    <section className={styles.registration}>
      <h2 className={styles.registration__heading}>
        REGISTER FOR EARLY BIRD BENEFITS
      </h2>

      <form className={styles.registration__form} onSubmit={submitHandler}>
        <img
          src="/broachure.png"
          alt="Broachure"
          className={styles.registration__form_broachure}
        />

        <h3 className={styles.registration__form_heading}>Register</h3>

        <div className={styles.registration__form_fields}>
          <div className={styles.registration__form_fields_input}>
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              placeholder="Your name"
              required
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>

          <div className={styles.registration__form_fields_input}>
            <label htmlFor="number">Mobile no.</label>
            <input
              id="number"
              type="number"
              placeholder="Your number"
              required
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            />
          </div>

          <div className={styles.registration__form_fields_input}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Your email id"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>

          <div className={styles.registration__form_fields_input}>
            <label htmlFor="education">Education</label>
            <input
              id="education"
              type="text"
              placeholder="Your education"
              required
              onChange={(e) => setEducation(e.target.value)}
              value={education}
            />
          </div>

          <div className={styles.registration__form_fields_input}>
            <label htmlFor="college/university">College/University</label>
            <input
              id="college/university"
              type="text"
              placeholder="Your College/University"
              required
              onChange={(e) => setCollegeUniversity(e.target.value)}
              value={collegeUniversity}
            />
          </div>
        </div>

        <div className={styles.registration__form_radios}>
          <div className={styles.registration__form_radios_input}>
            <h3>Stream of study</h3>
            <div
              className={styles.registration__form_radios_input_options}
              onChange={(e) => setSteamOfStudy(e.target.value)}
            >
              <label>
                <input
                  type="radio"
                  value="Science"
                  name="steamOfStudy"
                  required
                />
                Science
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="Commerce"
                  name="steamOfStudy"
                  required
                />
                Commerce
              </label>
              <br />
              <label>
                <input type="radio" value="Arts" name="steamOfStudy" required />
                Arts
              </label>
              <br />
              <label>
                <input type="radio" value="None" name="steamOfStudy" required />
                None
              </label>
            </div>
          </div>

          <div className={styles.registration__form_radios_input}>
            <h3>Highest Educational Qualification</h3>
            <div
              className={styles.registration__form_radios_input_options}
              onChange={(e) => setEducationalQualification(e.target.value)}
            >
              <label>
                <input
                  type="radio"
                  value="Secondary/Higher Secondary Grade (SSC/HSC)"
                  name="education"
                  required
                />
                Secondary/Higher Secondary Grade (SSC/HSC)
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="Graduate (Diploma/Bachelors degree)"
                  name="education"
                  required
                />
                Graduate (Diploma/Bachelors degree)
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="College Student (Undergraduate)"
                  name="education"
                  required
                />
                College Student (Undergraduate)
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="Post Graduation (Masters Degree)"
                  name="education"
                  required
                />
                Post Graduation (Masters Degree)
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="Doctorate/above (Ph.D.)"
                  name="education"
                  required
                />
                Doctorate/above (Ph.D.)
              </label>
              <br />
              <label>
                <input type="radio" value="Other" name="education" required />
                Other
              </label>
            </div>
          </div>

          <div className={styles.registration__form_radios_input}>
            <h3>
              Are you currently residing in Jalgaon, Dhule or Nandurbar district
              of Maharashtra?
            </h3>
            <p>
              Students within the jurisdiction of KBCNMU, can be eligible for
              university sponsorship on a portion of the course fees. We will
              request adequate proof of residence during admissions
            </p>
            <div
              className={styles.registration__form_radios_input_options}
              onChange={(e) => setResiding(e.target.value)}
            >
              <label>
                <input type="radio" value="Yes" name="residing" required />
                Yes
              </label>
              <br />
              <label>
                <input type="radio" value="No" name="residing" required />
                No
              </label>
            </div>
          </div>

          <div className={styles.registration__form_radios_input}>
            <h3>How did you come to know about this program?</h3>
            <div
              className={styles.registration__form_radios_input_options}
              onChange={(e) => setProgramInfo(e.target.value)}
            >
              <label>
                <input
                  type="radio"
                  value="Notice in College grouds/newsletter"
                  name="programInfo"
                  required
                />
                Notice in College grouds/newsletter
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="From a Social Media/Group Chat post"
                  name="programInfo"
                  required
                />
                From a Social Media/Group Chat post
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="Referral from a friend"
                  name="programInfo"
                  required
                />
                Referral from a friend
              </label>
              <br />
              <label>
                <input type="radio" value="other" name="programInfo" required />
                Other
              </label>
            </div>
          </div>

          <div className={styles.registration__form_radios_input}>
            <h3>Do you have a laptop or personal computer?</h3>
            <p>
              The Computer must be capable of running Adobe Creative Cloud. For
              those who do not have access, based on the availability we can
              arrange for lab space on campus grounds.
            </p>
            <div
              className={styles.registration__form_radios_input_options}
              onChange={(e) => setLaptopPc(e.target.value)}
            >
              <label>
                <input
                  type="radio"
                  value="Yes, I can learn the course"
                  name="LaptopPC"
                  required
                />
                Yes, I can learn the course
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="No, I would like to avail of University equipment"
                  name="LaptopPC"
                  required
                />
                No, I would like to avail of University equipment
              </label>
            </div>
          </div>
        </div>

        <button className={styles.registration__form_btn}>
          {isPending ? "SUBMITTING..." : "SUBMIT"}
        </button>
      </form>
    </section>
  );
};

export default RegistrationSection;
