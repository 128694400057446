import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC4xYwTBc54qLo97XcRqFW1f1mpwfgbsrA",
  authDomain: "nmu-graphics-landing-page.firebaseapp.com",
  projectId: "nmu-graphics-landing-page",
  storageBucket: "nmu-graphics-landing-page.appspot.com",
  messagingSenderId: "189021206794",
  appId: "1:189021206794:web:6ad8fc802efc43831262f3",
  measurementId: "G-HWKX8WBR48",
};

// Init Firebase \\
firebase.initializeApp(firebaseConfig);

// Init Services \\
const projectFirestore = firebase.firestore();

// Timestamp \\
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, timestamp };
