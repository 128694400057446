import React from "react";

// Styles \\
import styles from "./AboutCourseSection.module.scss";

const AboutCourseSection = () => {
  const state = [
    {
      imgUrl: "/1.png",
      text: "Adobe Illustrator is a vector graphics editor and design program developed and marketed by Adobe Inc. Originally designed for the Apple Macintosh, development of Adobe Illustrator began in 1985. Along with Creative Cloud, Illustrator CC was released.",
      id: 1,
    },
    {
      imgUrl: "/2.png",
      text: "Adobe Photoshop is a raster graphics editor developed and published by Adobe Inc. for Windows and macOS. It was originally created in 1988 by Thomas and John Knoll. Since then, the software has become the industry standard not only in raster graphics editing, but in digital art as a whole.",
      id: 2,
    },
    {
      imgUrl: "/3.png",
      text: "Adobe InDesign is a desktop publishing and page layout designing software application produced by Adobe Inc. It can be used to create works such as posters, flyers, brochures, magazines, newspapers, presentations, books and ebooks.",
      id: 3,
    },
    {
      imgUrl: "/4.png",
      text: "CorelDRAW is a vector graphics editor developed and marketed by Corel Corporation. It is also the name of the Corel graphics suite, which includes the bitmap-image editor Corel Photo-Paint as well as other graphics-related programs.",
      id: 4,
    },
  ];

  // JSX \\
  return (
    <section className={`${styles.about} container`}>
      <h2 className={styles.about__heading}>ABOUT THE COURSE</h2>

      <div className={styles.about__details}>
        {state.map((data) => (
          <div key={data.id} className={styles.about__details_item}>
            <div className={styles.about__details_item_photo}>
              <img src={data.imgUrl} alt={data.id} />
            </div>

            <div className={styles.about__details_item_description}>
              {data.text}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutCourseSection;
