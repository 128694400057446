import React from "react";

// Styles \\
import styles from "./Admin.module.scss";

// Custom Hooks \\
import { useGetCollection } from "../../hooks/useGetCollection";

const Admin = () => {
  // Getting 'Registered Users' Collection Form Firebase Firestore \\
  const { documents } = useGetCollection("registered-users", [
    "createdAt",
    "desc",
  ]);

  // Seconds To Date & Time \\
  const dateAndTime = (seconds) => {
    // prettier-ignore
    const monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    let date = new Date(seconds * 1000).getDate(),
      monthIndex = new Date(seconds * 1000).getMonth(),
      year = new Date(seconds * 1000).getFullYear(),
      hours = new Date(seconds * 1000).getHours(),
      minutes = new Date(seconds * 1000).getMinutes();

    if (String(minutes).length === 1) minutes = `0${minutes}`;
    if (String(hours).length === 1) hours = `0${hours}`;

    return `${date}_${monthNames[monthIndex]}_${year} ${hours}:${minutes}`;
  };

  // JSX \\
  return (
    <main className={styles.admin}>
      <h1 className={styles.admin__heading}>Submitted Users Information</h1>

      <table className={styles.admin__table}>
        <thead className={styles.admin__table_head}>
          <tr>
            <th>Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Education</th>
            <th>College/University</th>
            <th>Stream of Study</th>
            <th>Highest Educational Qualification</th>
            <th>Residing in Jalgaon, Dhule or Nandurbar</th>
            <th>How did you come to know about this program</th>
            <th>Have a Laptop or PC?</th>
            <th>Date & Time</th>
          </tr>
        </thead>

        <tbody className={styles.admin__table_body}>
          {documents &&
            documents.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val.name}</td>
                  <td>{val.number}</td>
                  <td>{val.email}</td>
                  <td>{val.education}</td>
                  <td>{val.collegeUniversity}</td>
                  <td>{val.steamOfStudy}</td>
                  <td>{val.educationalQualification}</td>
                  <td>{val.residing}</td>
                  <td>{val.programInfo}</td>
                  <td>{val.laptopPc}</td>
                  <td>{dateAndTime(val.createdAt.seconds)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </main>
  );
};

export default Admin;
