import React from "react";

// Styles And Icons \\
import styles from "./Footer.module.scss";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiFillLinkedin,
  AiFillPhone,
} from "react-icons/ai";
import { FaAddressCard } from "react-icons/fa";

const Footer = () => {
  // JSX \\
  return (
    <footer className={styles.footer}>
      <ul className={`${styles.footer__list} container`}>
        <li className={styles.footer__list_item}>
          <a href="https://www.facebook.com/develearntechnologies">
            <AiFillFacebook />
            <span>Facebook</span>
          </a>
        </li>

        <li className={styles.footer__list_item}>
          <a href="https://www.instagram.com/develearn.institute">
            <AiFillInstagram />
            <span>Instagram</span>
          </a>
        </li>

        <li className={styles.footer__list_item}>
          <a href="https://www.youtube.com/channel/UCcogq69TuueOSjihB6ll4Jw">
            <AiFillYoutube />
            <span>Youtube</span>
          </a>
        </li>

        <li className={styles.footer__list_item}>
          <a href="https://www.linkedin.com/company/develearn-technologies">
            <AiFillLinkedin />
            <span>LinkedIn</span>
          </a>
        </li>

        <li className={styles.footer__list_item}>
          <a href="tel:1800-210-2989">
            <AiFillPhone />
            <span>1800 210 2989</span>
          </a>
        </li>

        <li className={styles.footer__list_item}>
          <a href="https://www.google.com/maps/place/DeveLearn+Data+Science+Institute/@19.0262774,72.8536113,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7cf28d309c5a9:0x87f49959b01ff035!8m2!3d19.0262774!4d72.8558">
            <FaAddressCard />
            <span>Address</span>
          </a>
        </li>
      </ul>

      <div className={styles.footer__copyright}>
        Develearn technology private limited
      </div>
    </footer>
  );
};

export default Footer;
